// import React, { useState } from "react"

// export const CalendarTimeSlotSelector = ({
//   selectedTimeSlot,
//   onTimeSlotChange,
//   timeSlots,
// }) => {
//   const [selectedDay, setSelectedDay] = useState(Object.keys(timeSlots)[0])

//   React.useEffect(() => {
//     setSelectedDay(Object.keys(timeSlots)[0])
//   }, [timeSlots])

//   const styles = {
//     container: {
//       border: "1px solid #e1e1e1",
//       borderRadius: "8px",
//       overflow: "hidden",
//     },
//     daysContainer: {
//       display: "flex",
//       borderBottom: "1px solid #e1e1e1",
//     },
//     dayTab: {
//       padding: "15px",
//       flex: 1,
//       textAlign: "center",
//       cursor: "pointer",
//       backgroundColor: "#f8f9fa",
//       transition: "all 0.3s ease",
//     },
//     activeDayTab: {
//       backgroundColor: "#fff",
//       borderBottom: "3px solid #e07845",
//       fontWeight: "600",
//     },
//     timeSlotsContainer: {
//       padding: "20px",
//     },
//     timeSlot: {
//       display: "flex",
//       alignItems: "center",
//       padding: "12px",
//       margin: "8px 0",
//       border: "2px solid #e1e1e1",
//       borderRadius: "6px",
//       cursor: "pointer",
//       transition: "all 0.3s ease",
//     },
//     selectedTimeSlot: {
//       backgroundColor: "#e07845",
//       color: "#fff",
//       borderColor: "#e07845",
//     },
//     timeIcon: {
//       marginRight: "10px",
//     },
//     availabilityTag: {
//       marginLeft: "auto",
//       fontSize: "12px",
//       padding: "4px 8px",
//       borderRadius: "12px",
//       backgroundColor: "#e0f2e9",
//       color: "#287d3c",
//     },
//   }

//   return (
//     <div style={styles.container}>
//       <div style={styles.daysContainer}>
//         {Object.keys(timeSlots).map(day => (
//           <div
//             key={day}
//             style={{
//               ...styles.dayTab,
//               ...(selectedDay === day ? styles.activeDayTab : {}),
//             }}
//             onClick={() => setSelectedDay(day)}
//           >
//             {day}
//           </div>
//         ))}
//       </div>
//       <div style={styles.timeSlotsContainer}>
//         {selectedDay &&
//           timeSlots[selectedDay].map(time => {
//             const timeSlotId = `${selectedDay}-${time}`
//             const isSelected = selectedTimeSlot === timeSlotId

//             return (
//               <div
//                 key={timeSlotId}
//                 style={{
//                   ...styles.timeSlot,
//                   ...(isSelected ? styles.selectedTimeSlot : {}),
//                 }}
//                 onClick={() =>
//                   onTimeSlotChange({
//                     target: {
//                       name: "selectedTimeSlot",
//                       value: timeSlotId,
//                     },
//                   })
//                 }
//               >
//                 <span style={styles.timeIcon}>⏰</span>
//                 {time}
//                 <span style={styles.availabilityTag}>{time}</span>
//               </div>
//             )
//           })}
//       </div>
//     </div>
//   )
// }

import React, { useState, useEffect } from "react"

export const CalendarTimeSlotSelector = ({
  selectedTimeSlot,
  onTimeSlotChange,
  timeSlots,
}) => {
  const [selectedDay, setSelectedDay] = useState(Object.keys(timeSlots)[0])

  React.useEffect(() => {
    setSelectedDay(Object.keys(timeSlots)[0])
  }, [timeSlots])

  const styles = {
    container: {
      border: "1px solid #e1e1e1",
      borderRadius: "8px",
      overflow: "hidden",
    },
    daysContainer: {
      display: "flex",
      borderBottom: "1px solid #e1e1e1",
      overflowX: "auto", // For mobile responsiveness
      WebkitOverflowScrolling: "touch",
    },
    dayTab: {
      padding: "15px",
      flex: "1",
      minWidth: "100px", // For mobile responsiveness
      textAlign: "center",
      cursor: "pointer",
      backgroundColor: "#f8f9fa",
      transition: "all 0.3s ease",
      borderRight: "1px solid #e1e1e1",
    },
    activeDayTab: {
      backgroundColor: "#fff",
      borderBottom: "3px solid #e07845",
      fontWeight: "600",
      color: "#e07845",
    },
    timeSlotsContainer: {
      padding: "20px",
      backgroundColor: "#fff",
    },
    timeSlot: {
      display: "flex",
      alignItems: "center",
      padding: "12px",
      margin: "8px 0",
      border: "2px solid #e1e1e1",
      borderRadius: "6px",
      cursor: "pointer",
      transition: "all 0.3s ease",
      backgroundColor: "#fff",
    },
    selectedTimeSlot: {
      backgroundColor: "#e07845",
      color: "#fff",
      borderColor: "#e07845",
    },
    disabledTimeSlot: {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#f8f9fa",
    },
    timeIcon: {
      marginRight: "10px",
      fontSize: "16px",
    },
    availabilityTag: {
      marginLeft: "auto",
      fontSize: "12px",
      padding: "4px 8px",
      borderRadius: "12px",
      transition: "all 0.3s ease",
    },
    noSlots: {
      textAlign: "center",
      padding: "20px",
      color: "#666",
    },
    "@media (max-width: 768px)": {
      dayTab: {
        padding: "10px",
        fontSize: "14px",
      },
      timeSlot: {
        padding: "10px",
      },
    },
  }

  const getAvailabilityStatus = capacity => {
    if (capacity === 0) {
      return {
        label: "Full",
        color: "#da1414",
        backgroundColor: "#feefef",
      }
    } else if (capacity === 2) {
      return {
        label: `${capacity} spots left`,
        color: "#b95000",
        backgroundColor: "#fff4ec",
      }
    } else if (capacity === 1) {
      return {
        label: `${capacity} spot left`,
        color: "#b95000",
        backgroundColor: "#fff4ec",
      }
    } else {
      return {
        label: `${capacity} spots available`,
        color: "#287d3c",
        backgroundColor: "#e0f2e9",
      }
    }
  }

  return (
    <div style={styles.container}>
      {/* Days tabs */}
      <div style={styles.daysContainer}>
        {Object.keys(timeSlots).map(day => (
          <div
            key={day}
            style={{
              ...styles.dayTab,
              ...(selectedDay === day ? styles.activeDayTab : {}),
            }}
            onClick={() => setSelectedDay(day)}
          >
            {day}
          </div>
        ))}
      </div>

      {/* Time slots */}
      <div style={styles.timeSlotsContainer}>
        {selectedDay && timeSlots[selectedDay].length > 0 ? (
          timeSlots[selectedDay].map(slot => {
            const timeSlotId = `${selectedDay}-${slot.time}`
            const isSelected = selectedTimeSlot === timeSlotId
            const availability = getAvailabilityStatus(slot.capacity)
            const isDisabled = slot.capacity === 0

            return (
              <div
                key={timeSlotId}
                style={{
                  ...styles.timeSlot,
                  ...(isSelected ? styles.selectedTimeSlot : {}),
                  ...(isDisabled ? styles.disabledTimeSlot : {}),
                }}
                onClick={() => {
                  if (!isDisabled) {
                    onTimeSlotChange({
                      target: {
                        name: "selectedTimeSlot",
                        value: timeSlotId,
                      },
                    })
                  }
                }}
              >
                <span style={styles.timeIcon}>⏰</span>
                {slot.time}
                <span
                  style={{
                    ...styles.availabilityTag,
                    backgroundColor: isSelected
                      ? "#fff3e0"
                      : availability.backgroundColor,
                    color: isSelected ? "#e07845" : availability.color,
                  }}
                >
                  {availability.label}
                </span>
              </div>
            )
          })
        ) : (
          <div style={styles.noSlots}>Please select a course</div>
        )}
      </div>
    </div>
  )
}
