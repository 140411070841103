import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const openCalendly = slug => {
  window.Calendly.initPopupWidget({
    url: `https://calendly.com/codingchamps/${slug}`,
  })
}

const Header = ({ siteTitle, activeMenu, enrollmentPage }) => (
  <header>
    <div className="container-fluid">
      <div className="header d-lg-flex justify-content-between align-items-center py-3 px-sm-3">
        <div id="logo">
          <h1>
            <Link to="/">
              <span className="logo mr-2">
                <img className="rounded-circle" src="../images/logo.jpeg " />
              </span>
              Coding Champs
            </Link>
          </h1>
        </div>

        {enrollmentPage ? "" : console.log("not enrollmentPage")}

        <div className="nav_w3ls">
          <nav>
            {enrollmentPage ? (
              <div></div>
            ) : (
              <>
                <label htmlFor="drop" className="toggle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    color="#E07845"
                    fill="currentColor"
                    class="bi bi-list"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </label>
                <input type="checkbox" id="drop" />
                <ul className="menu">
                  <li>
                    <Link
                      to="/"
                      className={activeMenu === "index" ? "active" : ""}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/courses"
                      className={activeMenu === "courses" ? "active" : ""}
                    >
                      Courses
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/faqs"
                      className={activeMenu === "faqs" ? "active" : ""}
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      // onClick={e => {
                      //   e.preventDefault()
                      //   openCalendly("")
                      // }}
                      className={activeMenu === "contact" ? "active" : ""}
                    >
                      <a
                        onClick={e => openCalendly("")}
                        rel="noopener noreferrer"
                        className="btn nav-button read-more text-white"
                      >
                        Book a Trial
                      </a>
                    </Link>
                  </li>
                </ul>
              </>
            )}
          </nav>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
