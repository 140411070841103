import React from "react"
import { useEffect, useState } from "react"
import Header from "../components/header"
import { CalendarTimeSlotSelector } from "../components/CalendarTimeSlot"

const CourseRegistrationForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    course: "",
    childAge: "",
    selectedTimeSlot: "",
  })
  const [timeSlots, setTimeSlots] = useState([])
  const [focusedField, setFocusedField] = useState(null)
  const [errors, setErrors] = useState({})

  const [isSubmitting, setIsSubmitting] = useState(false)
  // const [submitStatus, setSubmitStatus] = useState(null)

  const GOOGLE_SCRIPT_URL =
    "https://script.google.com/macros/s/AKfycbwnEcfpnNhQTWuQvaUqu_O8xDthmPqzO3Dz24Saq4mCPmt9WBReQmxz9oEZw8lLciaJLg/exec"
  const validateForm = () => {
    const newErrors = {}

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required"
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email"
    }

    // Course validation
    if (!formData.course) {
      newErrors.course = "Please select a course"
    }

    // Age validation
    if (!formData.childAge) {
      newErrors.childAge = "Age is required"
    } else if (formData.childAge < 5 || formData.childAge > 17) {
      newErrors.childAge = "Age must be between 5 and 16"
    }

    // Time slot validation
    if (!formData.selectedTimeSlot) {
      newErrors.selectedTimeSlot = "Please select a time slot"
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    setIsSubmitting(true)

    try {
      // Your Google Sheets submission code here
      const response = await fetch(GOOGLE_SCRIPT_URL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })

      // Handle successful submission
      const selectedCourse = courses.find(c => c.id === formData.course)
      // alert("submitted")
      window.location.href = selectedCourse.paymentLink
    } catch (error) {
      setErrors({ submit: "Failed to submit form. Please try again." })
    } finally {
      setIsSubmitting(false)
    }
  }

  const courses = [
    {
      id: "scratch",
      name: "Scratch Coding",
      price: "99/mon",
      paymentLink: "https://buy.stripe.com/cN26rBdvT8HfgpidQT",
      timeSlots: {
        Mon: [{ time: "7:15 PM - 08:15 PM EST", capacity: 1 }],
        Tues: [{ time: "4:00 PM - 5:00 PM EST", capacity: 2 }],
        Wed: [{ time: "5:05 PM - 6:05 PM EST", capacity: 4 }],
        Thurs: [{ time: "7:15 PM - 08:15 PM EST", capacity: 3 }],
        Fri: [{ time: "6:10 PM - 7:10 PM EST", capacity: 4 }],
        Sat: [
          { time: "12:15 - 1:15 PM EST", capacity: 2 },
          { time: "4:35 - 5:35 PM EST", capacity: 3 },
        ],
        Sun: [
          { time: "12:15 - 1:15 PM EST", capacity: 0 },
          { time: "4:35 - 5:35 PM EST", capacity: 0 },
        ],
      },
    },
    {
      id: "roblox_course",
      name: "Roblox Game Development",
      price: "129/mon",
      paymentLink: "https://buy.stripe.com/14kdU34Zn7Db5KEaEG",
      timeSlots: {
        Mon: [{ time: "4:00 PM - 5:00 PM EST", capacity: 2 }],
        Tues: [{ time: "5:05 PM - 6:05 PM EST", capacity: 1 }],
        Wed: [{ time: "7:15 PM - 8:15 PM EST", capacity: 3 }],
        Thurs: [{ time: "6:10 PM - 7:10 PM EST", capacity: 4 }],
        Fri: [{ time: "4:00 PM - 5:00 PM EST", capacity: 3 }],
        Sat: [
          { time: "12:15 - 1:15 PM EST", capacity: 3 },
          { time: "4:35 - 5:35 PM EST", capacity: 3 },
        ],
        Sun: [
          { time: "9:00 AM - 10:0 AM EST", capacity: 0 },
          { time: "1:20 - 2:20 PM EST", capacity: 0 },
        ],
      },
    },
    {
      id: "python_course",
      name: "Python Programming",
      price: "129/mon",
      paymentLink: "https://buy.stripe.com/eVa03d2Rf7Dbc92bIJ",
      timeSlots: {
        Mon: [{ time: "5:05 PM - 6:05 PM EST", capacity: 2 }],
        Tues: [{ time: "6:10 PM - 7:10 PM EST", capacity: 1 }],
        Wed: [{ time: "4:00 PM - 5:00 PM EST", capacity: 1 }],
        Thurs: [{ time: "5:05 PM - 6:05 PM EST", capacity: 3 }],
        Fri: [{ time: "7:15 PM - 8:15 PM EST", capacity: 4 }],
        Sat: [
          { time: "10:05 - 11:05 AM EST", capacity: 3 },
          { time: "2:25 - 3:25 PM EST", capacity: 4 },
        ],
        Sun: [{ time: "5:05 PM - 6:05 PM EST", capacity: 0 }],
      },
    },
    {
      id: "web_dev",
      name: "Web Development",
      price: "129/mon",
      paymentLink: "https://buy.stripe.com/fZeaHRgI54qZb4Y288",
      timeSlots: {
        Mon: [{ time: "6:10 PM - 7:10 PM EST", capacity: 4 }],
        Tues: [{ time: "7:15 PM - 8:15 PM EST", capacity: 3 }],
        Wed: [{ time: "6:10 PM - 7:10 PM EST", capacity: 2 }],
        Thurs: [{ time: "4:00 PM - 5:00 PM EST", capacity: 1 }],
        Fri: [{ time: "5:05 PM - 6:05 PM EST", capacity: 4 }],
        Sat: [
          { time: "3:30 - 4:30 PM EST", capacity: 3 },
          { time: "11:10 - 12:10 PM EST", capacity: 2 },
        ],
        Sun: [{ time: "5:05 PM - 6:05 PM EST", capacity: 0 }],
      },
    },
  ]

  const formStyles = {
    container: {
      maxWidth: "600px",
      margin: "0px auto",
      padding: "30px",
      backgroundColor: "#ffffff",
      borderRadius: "15px",
      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
      transform: "translateY(0)",
      transition: "all 0.3s ease",
    },
    header: {
      textAlign: "center",
      marginBottom: "30px",
    },
    title: {
      color: "#333",
      fontSize: "28px",
      marginBottom: "10px",
      fontWeight: "600",
    },
    subtitle: {
      color: "#666",
      fontSize: "16px",
    },
    formGroup: {
      marginBottom: "25px",
      position: "relative",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontWeight: "500",
      color: "#333",
      fontSize: "16px",
      transition: "color 0.3s ease",
    },
    input: {
      width: "100%",
      padding: "12px 16px",
      borderRadius: "8px",
      border: "2px solid #e1e1e1",
      fontSize: "16px",
      transition: "all 0.3s ease",
      backgroundColor: "#f8f9fa",
      outline: "none",
    },
    focusedInput: {
      borderColor: "#e07845",
      boxShadow: "0 0 0 4px rgba(224,120,69,0.1)",
      backgroundColor: "#fff",
    },
    select: {
      width: "100%",
      padding: "12px 16px",
      borderRadius: "8px",
      border: "2px solid #e1e1e1",
      fontSize: "16px",
      backgroundColor: "#f8f9fa",
      appearance: "none",
      cursor: "pointer",
      transition: "all 0.3s ease",
      outline: "none",
    },
    courseOption: {
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
    courseIcon: {
      marginRight: "8px",
      fontSize: "20px",
    },
    button: {
      width: "100%",
      padding: "14px 24px",
      backgroundColor: "#e07845",
      color: "#fff",
      border: "none",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      transition: "all 0.3s ease",
      transform: "translateY(0)",
      boxShadow: "0 4px 15px rgba(224,120,69,0.2)",
    },
    buttonHover: {
      backgroundColor: "#c9643a",
      transform: "translateY(-2px)",
      boxShadow: "0 6px 20px rgba(224,120,69,0.3)",
    },
    error: {
      color: "#dc3545",
      fontSize: "14px",
      marginTop: "5px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    priceTag: {
      display: "inline-block",
      padding: "4px 8px",
      backgroundColor: "#e0784522",
      color: "#e07845",
      borderRadius: "4px",
      fontSize: "14px",
      marginLeft: "8px",
    },
    // New styles for the engaging content
    wrapper: {
      maxWidth: "1200px",
      margin: "0px auto",
      padding: "20px",
      display: "flex",
      flexDirection: "row",
      gap: "40px",
      alignItems: "flex-start", // Changed from 'center' to 'flex-start'
      "@media (max-width: 992px)": {
        flexDirection: "column",
      },
    },
    contentSection: {
      flex: "1",
      padding: "20px",
      // position: "sticky", // Optional: makes content stick while scrolling
      top: "20px", // Optional: for sticky positioning
    },
    formSection: {
      flex: "1",
      marginTop: "0", // Remove any top margin
    },
    container: {
      // Updated container styles
      backgroundColor: "#ffffff",
      borderRadius: "15px",
      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
      padding: "30px",
      marginTop: "0", // Remove top margin
    },
    imageContainer: {
      marginBottom: "30px",
      borderRadius: "15px",
      overflow: "hidden",
      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
      maxHeight: "300px", // Add max height to control image size
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover", // Ensures image covers area without distortion
      display: "block",
    },
    highlight: {
      color: "#e07845",
      fontWeight: "600",
    },
    testimonial: {
      backgroundColor: "#f8f9fa",
      padding: "20px",
      borderRadius: "10px",
      marginTop: "20px",
      borderLeft: "4px solid #e07845",
    },
    testimonialText: {
      fontStyle: "italic",
      color: "#666",
      marginBottom: "10px",
    },
    testimonialAuthor: {
      fontWeight: "600",
      color: "#333",
    },
  }

  const statusMessageStyles = {
    success: {
      backgroundColor: "#d4edda",
      color: "#155724",
      padding: "10px",
      borderRadius: "4px",
      marginBottom: "20px",
    },
    error: {
      backgroundColor: "#f8d7da",
      color: "#721c24",
      padding: "10px",
      borderRadius: "4px",
      marginBottom: "20px",
    },
    loading: {
      display: "inline-block",
      width: "20px",
      height: "20px",
      border: "3px solid #f3f3f3",
      borderTop: "3px solid #e07845",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    },
  }

  const ErrorMessage = ({ message }) => (
    <div
      style={{
        color: "#dc3545",
        fontSize: "14px",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      {message}
    </div>
  )

  const LoadingSpinner = () => <div style={statusMessageStyles.loading} />

  useEffect(() => {
    if (formData.course) {
      const selectedCourse = courses.find(c => c.id === formData.course)
      setTimeSlots(selectedCourse?.timeSlots || [])
    }
  }, [formData.course])

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const validateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  //   const handleSubmit = e => {
  //     e.preventDefault()

  //     if (!validateEmail(formData.email)) {
  //       alert("Please enter a valid email address")
  //       return
  //     }

  //     if (!formData.course || !formData.childAge || !formData.selectedTimeSlot) {
  //       alert("Please fill in all fields")
  //       return
  //     }

  //     const selectedCourse = courses.find(c => c.id === formData.course)
  //     // Redirect to Stripe payment link
  //     window.location.href = selectedCourse.paymentLink
  //   }

  return (
    <>
      <Header enrollmentPage={true} />
      <div className="row" style={formStyles.wrapper}>
        {/* Engaging Content Section */}
        <div
          className="col-lg-6 col-md-6 col-sm-12"
          style={formStyles.contentSection}
        >
          <h1
            style={{
              fontSize: "32px",
              color: "#333",
              marginBottom: "20px",
            }}
          >
            Let’s turn your kid’s screen time into{" "}
            <span style={formStyles.highlight}>Coding Time</span>
          </h1>

          <p
            style={{
              fontSize: "18px",
              color: "#666",
              lineHeight: "1.6",
              marginBottom: "25px",
            }}
          >
            Give your child the gift of future-ready skills with our interactive
            coding classes. Perfect for ages 5-17, our courses make learning to
            code fun and engaging!
          </p>

          <ul style={formStyles.benefitsList}>
            <li style={formStyles.benefitItem}>
              <span style={formStyles.benefitIcon}>✨</span>
              Live, Interactive Classes with Expert Instructors
            </li>
            <li style={formStyles.benefitItem}>
              <span style={formStyles.benefitIcon}>🎮</span>
              Learn Through Fun Projects and Games
            </li>
            <li style={formStyles.benefitItem}>
              <span style={formStyles.benefitIcon}>👥</span>
              Small Class Sizes (Maximum 4 Students)
            </li>
            <li style={formStyles.benefitItem}>
              <span style={formStyles.benefitIcon}>🌟</span>
              Personalized Attention and Feedback
            </li>
            <li style={formStyles.benefitItem}>
              <span style={formStyles.benefitIcon}>📱</span>
              Flexible Schedule and Online Learning
            </li>
          </ul>

          <div style={formStyles.testimonial}>
            <p style={formStyles.testimonialText}>
              "My daughter absolutely loves her coding classes! She's gone from
              playing games to creating them. The teachers are amazing and make
              learning fun. It's been a great investment in her future!"
            </p>
            <p style={formStyles.testimonialAuthor}>
              - Sarah M., Parent of 9-year-old Emma
            </p>
          </div>

          <div
            style={{
              backgroundColor: "#fff3e0",
              padding: "15px",
              borderRadius: "8px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span style={{ fontSize: "24px" }}>🎉</span>
            <p style={{ margin: 0, color: "#e07845", fontWeight: "500" }}>
              Limited Time Offer: Get your first class FREE!
            </p>
          </div>
        </div>
        {/* Form Section */}
        <div
          className="col-lg-6 col-md-6 col-sm-12"
          style={formStyles.container}
        >
          <div style={formStyles.header}>
            <h2 style={formStyles.title}>Course Registration</h2>
            <p style={formStyles.subtitle}>
              Start your child's coding journey today!
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div style={formStyles.formGroup}>
              <label
                style={{
                  ...formStyles.label,
                  color: focusedField === "email" ? "#e07845" : "#333",
                }}
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onFocus={() => setFocusedField("email")}
                onBlur={() => setFocusedField(null)}
                style={{
                  ...formStyles.input,
                  ...(focusedField === "email" ? formStyles.focusedInput : {}),
                }}
                placeholder="Enter your email"
                required
              />
            </div>

            <div style={formStyles.formGroup}>
              <label
                style={{
                  ...formStyles.label,
                  color: focusedField === "course" ? "#e07845" : "#333",
                }}
              >
                Select Course
              </label>
              <select
                name="course"
                value={formData.course}
                onChange={handleInputChange}
                onFocus={() => setFocusedField("course")}
                onBlur={() => setFocusedField(null)}
                style={{
                  ...formStyles.select,
                  ...(focusedField === "course" ? formStyles.focusedInput : {}),
                }}
                required
              >
                <option value="">Choose a course</option>
                {courses.map(course => (
                  <option key={course.id} value={course.id}>
                    {course.icon} {course.name} - ${course.price}
                  </option>
                ))}
              </select>
            </div>

            <div style={formStyles.formGroup}>
              <label
                style={{
                  ...formStyles.label,
                  color: focusedField === "childAge" ? "#e07845" : "#333",
                }}
              >
                Child's Age
              </label>
              <input
                type="number"
                name="childAge"
                value={formData.childAge}
                onChange={handleInputChange}
                onFocus={() => setFocusedField("childAge")}
                onBlur={() => setFocusedField(null)}
                style={{
                  ...formStyles.input,
                  ...(focusedField === "childAge"
                    ? formStyles.focusedInput
                    : {}),
                }}
                placeholder="Enter child's age"
                min="5"
                max="17"
                required
              />
            </div>

            <div style={formStyles.formGroup}>
              <label style={formStyles.label}>Select Time Slot</label>
              <CalendarTimeSlotSelector
                selectedTimeSlot={formData.selectedTimeSlot}
                onTimeSlotChange={handleInputChange}
                timeSlots={timeSlots}
              />
              {errors.selectedTimeSlot && (
                <ErrorMessage message={errors.selectedTimeSlot} />
              )}
            </div>

            {/* <div style={formStyles.formGroup}>
              <label
                style={{
                  ...formStyles.label,
                  color: focusedField === "timeSlot" ? "#e07845" : "#333",
                }}
              >
                Select Time Slot
              </label>
              <select
                name="selectedTimeSlot"
                value={formData.selectedTimeSlot}
                onChange={handleInputChange}
                onFocus={() => setFocusedField("timeSlot")}
                onBlur={() => setFocusedField(null)}
                style={{
                  ...formStyles.select,
                  ...(focusedField === "timeSlot"
                    ? formStyles.focusedInput
                    : {}),
                }}
                required
              >
                <option value="">Choose a time slot</option>
                {timeSlots.map((slot, index) => (
                  <option key={index} value={slot}>
                    {slot}
                  </option>
                ))}
              </select>
            </div> */}

            <button
              type="submit"
              style={formStyles.button}
              onMouseOver={e => {
                Object.assign(e.target.style, formStyles.buttonHover)
              }}
              onMouseOut={e => {
                e.target.style.backgroundColor = "#e07845"
                e.target.style.transform = "translateY(0)"
                e.target.style.boxShadow = "0 4px 15px rgba(224,120,69,0.2)"
              }}
            >
              Proceed to Payment
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default CourseRegistrationForm
